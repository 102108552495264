<script>
// import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";

import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Notulen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
  },
  data() {
    return {
      title: "Tambah Notulen",
      items: [
        {
          text: "Monitoring",
          href: "/",
        },
        {
          text: "Notulen",
          href: "/monitoring/notulen",
        },
        {
          text: "Tambah Notulen",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      tanggal_rapat: [],
      tanggal_rapat_selected: "",
      agenda_rapat:'',
      // variable Page
    };
  },
  mounted() {
    this.getTanggalRapat();
  },
  methods: {
    getTanggalRapat() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/administrasirapat/absensi")
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.tanggal_rapat = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    StoreData() {
      let self = this;
      var rapat_id = self.tanggal_rapat_selected?.ar_id;
      if(rapat_id){
        rapat_id = self.tanggal_rapat_selected?.ar_id;
      }else{
        rapat_id = '';
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      let required_form = new Promise(function (resolve, reject) {
        // cek required
        if (rapat_id == null || rapat_id == "" || self.pic == null || self.pic == "") {
          Swal.fire({
            icon: "error",
            title: "Peringatan...",
            text: "Pastikan Formulir Terisi Lengkap",
          });
          reject("stop");
        }
        resolve("next");
      });
      required_form.then(function (response) {
        if (response == "stop") {
          return false;
        }
        var FormData = require("form-data");
        var data = new FormData();
        data.append("nr_absensi_rapat_id", rapat_id);
        data.append("nr_pic", self.pic);
        data.append("nr_catatan", self.catatan);
        data.append("id_session_user", Cookies.get("session_id"));

        var config = {
          method: "post",
          url:
            process.env.VUE_APP_BACKEND_URL_VERSION + "/api/monitoring/notulen/store",
          data: data,
        };
        axios(config)
          .then(function (response) {
            var response_data = response.data; 
            if(response_data.code != 200){
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: response_data.message,
              });
            }else{
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman notulen segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.$emit("refresh-table", "add");
                }
              });
            }
          })
          .catch((e) => {
            this.axiosCatchError = e.response.data.data;
            Swal.close();
          });
      });
    },
    tanggalRapatSelected(){
      let self = this;
      var rapat_id = self.tanggal_rapat_selected?.ar_id;

      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/administrasirapatagenda/absensiagenda?rapat_id="+rapat_id)
        .then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
          self.agenda_rapat = response_data.list_rapat;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.message,
          });
        }
      });
    },
  },
};
</script>

<template>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <form @submit.prevent="StoreData">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="">Tanggal Rapat Direksi <span class="text-danger">* Wajib diisi</span></label>
                  <v-select
                    placeholder="-Pilih Tanggal Rapat-"
                    :options="tanggal_rapat"
                    label="ar_tanggal"
                    v-model="tanggal_rapat_selected"
                    @update:modelValue="tanggalRapatSelected"
                  ></v-select>
                </div>
                <b-form-group
                  class="mb-3"
                  label="Agenda Rapat"
                  label-for="formrow-agenda-rapat-input"
                >
                  <textarea
                    class="form-control"
                    rows="4"
                    v-model="agenda_rapat"
                    readonly
                  >
                </textarea>
                </b-form-group>
                <div class="form-group">
                  <label for="">PIC <span class="text-danger">* Wajib diisi</span></label>
                  <b-form-input id="formrow-pic-input" type="text" v-model="pic"></b-form-input>
                </div>
                <b-form-group
                  class="mb-3"
                  label="Catatan/Reminder"
                  label-for="formrow-catatan-reminder-input"
                >
                  <textarea
                    name="catatan_reminder"
                    id=""
                    rows="4"
                    class="form-control"
                    v-model="catatan"
                  ></textarea>
                </b-form-group>
              </div>
            </div>

            <div class="text-end pt-5">
              <b-button type="submit" variant="primary" class="ms-1"><i class="fas fa-save"></i> Simpan</b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>
