<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import FormAdd from "./add-notulen.vue";
import FormEdit from "./edit-notulen.vue";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Notulen",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    FormAdd,
    FormEdit,
  },
  data() {
    return {
      title: "Notulen",
      items: [
        {
          text: "Monitoring",
          href: "/",
        },
        {
          text: "Notulen Rapat Direksi",
          active: true,
        },
      ],
      role_slug: Cookies.get("session_role_slug"),
      // variable Page Table
      loadingTable: true,
      showModalAdd: false,
      showFormAdd: false,
      showModalEdit: false,
      showFormEdit: false,
      table_data: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      selected: [],
      tanggal: "",
      search: "",
      idDataEdit: 0,
    };
  },
  mounted() {
    this.getDataTable();
  },
  methods: {
    getDataTable() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/monitoring/notulen?tanggal=" +
            self.tanggal +
            "&search=" +
            self.search
        )
        .then(response => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data = response_data.list_data.data;
            //console.log('AAAAAA');
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    hapusData(jb_id) {
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then(result => {
        if (result.isConfirmed) {
          let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", jb_id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/monitoring/notulen/hapus",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text:
                  "Anda akan diarahkan ke halaman monitoring notulen segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then(result => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.getDataTable();
                }
              });
            })
            .catch(e => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },
    //filter data
    filterData() {
      this.getDataTable();
    },
    deleteselected() {
      let self = this;
      Swal.fire({
        title: "Perhatian",
        text: "Anda yakin ingin menghapus data tersebut?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then(result => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("id", self.selected);
          var config = {
            method: "POST",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/monitoring/notulen/multiplehapus",
            data: data,
          };
          axios(config).then(response => {
            console.log(response);
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Data berhasil dihapus.",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              didOpen: () => {
                timerInterval = setInterval(() => {
                  const content = Swal.getContent();
                  if (content) {
                    const b = content.querySelector("b");
                    if (b) {
                      b.textContent = Swal.getTimerLeft();
                    }
                  }
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then(result => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.getDataTable();
              }
            });
          });
        } else {
          Swal.close();
        }
      });
    },

    eksporHandle() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/monitoring/notulen/exportexcel?tanggal=" +
        self.tanggal;
      link.setAttribute("target", "_blank");
      link.click();
    },

    formAddData() {
      let self = this;
      self.showModalAdd = false;
      self.showFormAdd = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalAdd = true;
        self.showFormAdd = true;
      });
    },
    formEditData(idDataEdit) {
      let self = this;
      self.showModalEdit = false;
      self.showFormEdit = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalEdit = true;
        self.showFormEdit = true;
        self.idDataEdit = idDataEdit;
      });
    },

    refreshTable(type) {
      let self = this;
      if (type == "add") {
        self.showModalAdd = false;
        self.showFormAdd = false;
      }else{
        self.showModalEdit = false;
        self.showModalEdit = false;
      }
      self.getDataTable();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <br />
                <div class="row">
                  <div class="col-md-12">
                    <div class="">
                      <div class="row" style="margin-bottom: 10px;">
                        <div class="col-md-2">
                          <label for="">Tanggal Notulen</label>
                          <input
                            type="date"
                            class="form-control"
                            v-model="tanggal"
                          />
                        </div>
                        <div class="col-md-2">
                          <label for="">&nbsp;</label><br />
                          <button
                            class="btn btn-primary rounded-0"
                            @click="filterData"
                            type="button"
                          >
                            <i class="fa fa-search"></i> Filter
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 text-end">
                          <button
                            @click="eksporHandle"
                            class="btn btn-success m-1 rounded-0"
                          >
                            <i class="fas fa-file-excel"></i> Ekspor Data
                          </button>
                          <button
                            type="button"
                            v-on:click="formAddData"
                            class="btn btn-info m-1 rounded-0"
                          >
                            <i class="fa fa-plus"></i> Tambah Notulen
                          </button>
                        </div>
                      </div>
                    </div>
                    &nbsp;
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-9"></div>
                  <div class="col-md-3">
                    <input
                      type="text"
                      class="form-control"
                      id="searchTable"
                      v-model="search"
                      @input="getDataTable()"
                      placeholder="Cari Data ..."
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table mb-0 table-bordered table-condensed table-hover table-striped"
                  >
                    <thead>
                      <tr>
                        <th class="text-center text-white" style="width: 50px;background-color: #132d4a;">#</th>
                        <th class="text-center text-white" style="width: 50px;background-color: #132d4a;">No</th>
                        <th class="text-center text-white" style="width: 75px;background-color: #132d4a;">Notulen Tanggal</th>
                        <th class="text-center text-white" style="width: 200px;background-color: #132d4a;">Agenda</th>
                        <th class="text-center text-white" style="width: 150px;background-color: #132d4a;">PIC Notulen</th>
                        <th class="text-center text-white" style="width: 100px;background-color: #132d4a;">Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="6">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="table_data == ''">
                        <td class="text-center" colspan="6">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr
                        v-else
                        v-for="(row_data, key_data) in table_data"
                        :key="key_data"
                      >
                        <td class="text-center">
                          <input
                            type="checkbox"
                            v-model="selected"
                            @change="selectItem"
                            :value="row_data.nr_id"
                          />
                        </td>
                        <td class="text-center">
                          {{ key_data + 1 }}
                        </td>
                        <td>
                          <div>
                            {{ row_data.nr_tanggal_awal_sirkuler.split(" ")[0] }}
                          </div>
                        </td>
                        <td class="text-start">
                          <div v-if="row_data.agenda_rapat">
                            <ol>
                              <li
                                v-for="(item,
                                key_items) in row_data.agenda_rapat.split(';')"
                                :key="item"
                              >
                                {{ key_items + 1 }}. {{ item }}
                              </li>
                            </ol>
                          </div>
                        </td>
                        <td>
                          <span style="font-size: 14px;">{{
                            row_data.nr_pic
                          }}</span>
                        </td>
                        <td class="text-center">
                          <div class="btn-group" role="group">
                            <!-- <button
                              type="button"
                              v-on:click="formEditData(row_data.nr_id)"
                              class="btn btn-sm btn-info"
                            >
                              <i class="fa fa-edit"></i> Edit
                            </button> -->
                            <router-link
                              :to="{
                                name: 'detail-notulen',
                                params: { id: row_data.nr_id },
                              }"
                              class="btn btn-sm btn-primary"
                              ><i class="fa fa-eye"></i> Detail</router-link
                            >
                            <!-- <button
                              v-if="role_slug == 'super_admin'"
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-on:click="hapusData(row_data.nr_id)"
                            >
                              <i class="fa fa-trash"></i> Hapus
                            </button> -->
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button
                  href="#"
                  class="btn btn-danger m-1"
                  @click="deleteselected"
                >
                  <i class="fa fa-trash"></i> Hapus Data Terpilih
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <!-- Popup Form Tambah Data -->
  <b-modal
    v-model="showModalAdd"
    title="Tambah Notulen"
    title-class="text-black font-18"
    body-class="p-3"
    size="md"
    hide-footer
  >
    <form-add
      v-if="showFormAdd"
      @refresh-table="refreshTable('add')"
    ></form-add>
  </b-modal>
  <b-modal
    v-model="showModalEdit"
    title="Edit Notulen"
    title-class="text-black font-18"
    body-class="p-3"
    size="md"
    hide-footer
  >
    <form-edit
      v-if="showFormEdit"
      :idDataEdit="idDataEdit"
      @refresh-table="refreshTable('edit')"
    ></form-edit>
  </b-modal>
</template>
